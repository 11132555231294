<template>
  <div id="app">
    <div style="width: 100%;height: 30px; text-align: left; margin: 30px; font-weight: bold; display: flex; vertical-align: middle;">
      <div>
        <img src="@/assets/logo.png" style="height: 100%;">
      </div>
      <div>SKPAY对接文档</div>
    </div>
    <el-row :gutter="20">
      <el-col :span="8">
        <div>
          <el-row class="tac">
            <el-col :span="8">
              <el-menu
                default-active="2"
                class="el-menu-vertical-demo"
                :router="true"
                :unique-opened="false"
              >
                <el-submenu index="1">
                  <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>前言</span>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item index="Prepare">准备工作</el-menu-item>
                    <el-menu-item index="Prepare">注意事项</el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
                <el-submenu index="1">
                  <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>加密</span>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item index="EnCode">加密流程与示例</el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
                <el-submenu index="1">
                  <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>南非</span>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item index="Recharge">充值对接</el-menu-item>
                  </el-menu-item-group>
                  <el-menu-item-group>
                    <el-menu-item index="SearchRecharge">充值查询</el-menu-item>
                  </el-menu-item-group>
                  <el-menu-item-group>
                    <el-menu-item index="Withdrawal">提现对接</el-menu-item>
                  </el-menu-item-group>
                  <el-menu-item-group>
                    <el-menu-item index="CallBack">回调通知</el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
              </el-menu>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="container">
          <keep-alive>
            <router-view />
          </keep-alive>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  },
  mounted(){}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 30px;
}
</style>
