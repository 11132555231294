import Vue from 'vue'
import App from './App.vue'
import { Button, Field } from 'vant'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { router } from './router';
Vue.use(Button)
Vue.use(Field)
Vue.use(ElementUI);


Vue.config.productionTip = false

new Vue({
  router,
  el: '#app',
  render: h => h(App),
}).$mount('#app')
