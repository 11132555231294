import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

const routes = [
  {
    path: '/',
    redirect: '/Prepare'
  },
  {
    // 前言
    name: 'Prepare',
    path: '/Prepare',
    component: () => import('@/components/Prepare.vue'),
    meta: {
      title: '前言', isAuth: false,
    },
  },
  {
    // 加密
    name: 'EnCode',
    path: '/EnCode',
    component: () => import('@/components/EnCode.vue'),
    meta: {
      title: '加密', isAuth: false,
    },
  },
  {
    // 充值
    name: 'Recharge',
    path: '/Recharge',
    component: () => import('@/components/Recharge.vue'),
    meta: {
      title: '充值接口', isAuth: false,
    },
  },
  {
    // 充值查询
    name: 'SearchRecharge',
    path: '/SearchRecharge',
    component: () => import('@/components/SearchRecharge.vue'),
    meta: {
      title: '充值查询接口', isAuth: false,
    },
  },
  {
    // 提现
    name: 'Withdrawal',
    path: '/Withdrawal',
    component: () => import('@/components/Withdrawal.vue'),
    meta: {
      title: '提款接口', isAuth: false,
    },
  },
  {
    // 回调
    name: 'CallBack',
    path: '/CallBack',
    component: () => import('@/components/CallBack.vue'),
    meta: {
      title: '回调', isAuth: false,
    },
  },

];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ 
  //制定路由工作模式
   mode: 'history',
  routes 
});

//前置路由守卫限制登录
// 如果用户已经登录，放行。否则跳到用户登录页
router.beforeEach((to, from, next) => {
  if(to.meta.isAuth){
    if(window.sessionStorage.getItem("PhoneNumber")){
      next()
    }else{
      router.push("login")
    }
  }else{
    next()
  }
})


// 后置路由守卫换title
router.afterEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
})

export {
  router
};
